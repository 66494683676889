import React, { useEffect } from "react"
import ConfettiGenerator from "confetti-js"

const Canva: React.FC = props => {
  useEffect(() => {
    const confettiSettings = {
      target: "confetti",
      clock: 20,
      rotate: true,
      max: 80,
      respawn: false,
      start_from_edge: true,
    }

    const confetti = new ConfettiGenerator(confettiSettings)
    confetti.render()

    return () => confetti.clear()
  }, [])

  return (
    <canvas
      id="confetti"
      style={{
        userSelect: "none",
        pointerEvents: "none",
      }}
      className="fixed inset-0 z-30 w-full h-full select-none"
    />
  )
}

export const Confetti: React.FC<{
  isOpen: boolean
}> = props => {
  return props.isOpen ? <Canva /> : <></>
}

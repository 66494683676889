import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Transition } from "@headlessui/react"
import { getImage } from "gatsby-plugin-image"

import CheckIcon from "@heroicons/react/outline/CheckIcon"
import ChevronLeftIcon from "@heroicons/react/outline/ChevronLeftIcon"
import XIcon from "@heroicons/react/outline/XIcon"

import {
  Breadcrumb,
  Footer,
  Hero,
  Navigation,
} from "../../components/Marketing"
import { Seo } from "../../components/Seo/Seo"
import { Confetti } from "../../components/Confetti/Confetti"
import { Share } from "../../components/Quizz/Share"
import { Syllabe } from "../../components/article/Syllabe"

const Correction: React.FC<{
  corrections: Array<{
    id: string
    succeed: boolean
    text: string[]
    answer: string
  }>
}> = props => {
  return (
    <div className="mt-8">
      <ul role="list" className="space-y-4">
        {props.corrections.map(correction => (
          <li key={correction.id}>
            <div className="block bg-gray-100 rounded-md">
              <div className="flex items-center px-4 py-6 sm:px-6">
                <div className="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
                  <div className="flex items-center">
                    <div
                      className={`${
                        correction.succeed ? "bg-green-500" : "bg-red-500"
                      } flex items-center justify-center  w-6 h-6 rounded-full`}
                    >
                      {correction.succeed && (
                        <CheckIcon className="w-5 h-5 text-white" />
                      )}

                      {!correction.succeed && (
                        <XIcon className="w-5 h-5 text-white" />
                      )}
                    </div>

                    <p className="pl-4 text-lg font-medium text-gray-900 md:text-xl">
                      {correction.text.map(v => (
                        <span key={v}>
                          {v === " " ? " " : <Syllabe>{v}</Syllabe>}
                        </span>
                      ))}
                    </p>

                    <p className="hidden pl-4 text-gray-700 md:block">
                      {correction.answer} syllabes
                    </p>
                  </div>

                  <p className="pl-4 text-right text-gray-700 md:hidden">
                    {correction.answer} syllabes
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const Steps: React.FC<{
  steps: number
  current: number
}> = ({ steps, current }) => {
  return (
    <nav>
      <ol role="list" className="flex items-center justify-center">
        {Array.from({ length: steps }).map((step, index) => (
          <li
            key={index}
            className={classNames(
              index !== steps - 1 ? "pr-4 sm:pr-8" : "",
              "relative"
            )}
          >
            {index < current ? (
              <>
                <div
                  className="absolute inset-0 flex items-center justify-center"
                  aria-hidden="true"
                >
                  <div className="w-full h-1 bg-green-500" />
                </div>
                <span className="relative flex items-center justify-center w-4 h-4 bg-green-500 rounded-full md:w-6 md:h-6"></span>
              </>
            ) : index === current ? (
              <>
                <div
                  className="absolute inset-0 flex items-center justify-center"
                  aria-hidden="true"
                >
                  <div className="w-full h-1 bg-gray-200" />
                </div>
                <span
                  className="relative flex items-center justify-center w-4 h-4 bg-white border-2 border-green-600 rounded-full md:w-6 md:h-6"
                  aria-current="step"
                >
                  <span
                    className="w-2 h-2 bg-green-500 rounded-full"
                    aria-hidden="true"
                  />
                </span>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center justify-center"
                  aria-hidden="true"
                >
                  <div className="w-full h-1 bg-gray-200" />
                </div>
                <span className="relative flex items-center justify-center w-4 h-4 bg-white border-2 border-gray-300 rounded-full md:w-6 md:h-6"></span>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

const Answer: React.FC<{
  selected?: boolean
  value: string
  onClick: Function
}> = ({ value, onClick, selected }) => (
  <div
    className="relative p-4 pt-4 text-center cursor-pointer group"
    onClick={() => onClick()}
  >
    <div
      className={`rounded-lg top-0 left-0 right-0 bottom-0 transition duration-300 ease-in-out transform absolute ${
        selected
          ? "bg-white z-10 scale-y-125 shadow-2xl"
          : "bg-gray-200 scale-100 shadow-none"
      } group-hover:bg-white group-hover:z-10 group-hover:scale-y-125 group-hover:shadow-2xl`}
    ></div>
    <span
      className={`${
        selected && "z-10 text-green-600"
      } group-hover:z-10 group-hover:text-green-600 relative text-gray-900 font-semibold text-xl md:text-2xl transition-colors duration-300`}
    >
      {value}
    </span>
  </div>
)

const questions = [
  {
    id: "1",
    value: "Concombre",
    syllabes: ["Con", "com", "bre"],
    answers: [{ value: "2" }, { value: "3" }, { value: "4" }, { value: "5" }],
    goodAnswer: "3",
    choseAnswer: null,
  },
  {
    id: "2",
    value: "Famille",
    syllabes: ["Fa", "mille"],
    answers: [{ value: "1" }, { value: "2" }, { value: "3" }, { value: "4" }],
    goodAnswer: "2",
    choseAnswer: null,
  },
  {
    id: "3",
    value: "Ma fille adorée",
    syllabes: ["Ma", " ", "fi", "lle a", "do", "rée"],
    answers: [{ value: "3" }, { value: "4" }, { value: "5" }, { value: "6" }],
    goodAnswer: "5",
    choseAnswer: null,
  },
  {
    id: "4",
    value: "La terre plate",
    syllabes: ["La", " ", "terre", " ", "pla", "te"],
    answers: [{ value: "2" }, { value: "3" }, { value: "4" }, { value: "5" }],
    goodAnswer: "4",
    choseAnswer: null,
  },
  {
    id: "5",
    value: "Je suis allé à l'école",
    syllabes: ["Je", " ", "suis", " ", "allé à", " ", "l'é", "colle"],
    answers: [{ value: "7" }, { value: "8" }, { value: "9" }, { value: "10" }],
    goodAnswer: "7",
    choseAnswer: null,
  },
  {
    id: "6",
    value: "Le moineau sautille",
    syllabes: ["Le", " ", "moi", "neau", " ", "sau", "tille"],
    answers: [{ value: "3" }, { value: "4" }, { value: "5" }, { value: "6" }],
    goodAnswer: "5",
    choseAnswer: null,
  },
  {
    id: "7",
    value: "Je pleurais dans le fauteuil d'osier",
    syllabes: [
      "Je",
      " ",
      "pleu",
      "rais",
      " ",
      "dans",
      "le",
      " ",
      "fau",
      "teuil",
      " ",
      "d'o",
      "sier",
    ],
    answers: [{ value: "8" }, { value: "9" }, { value: "10" }, { value: "11" }],
    goodAnswer: "9",
    choseAnswer: null,
  },
  {
    id: "8",
    value: "La lune est fraîche",
    syllabes: ["La", " ", "lu", "ne est", " ", "fraî", "che"],
    answers: [{ value: "3" }, { value: "4" }, { value: "5" }, { value: "6" }],
    goodAnswer: "5",
    choseAnswer: null,
  },
  {
    id: "9",
    value: "La phosphorescence marine",
    syllabes: ["La", " ", "phos", "pho", "re", "scen", "ce", " ", "ma", "rine"],
    answers: [{ value: "5" }, { value: "6" }, { value: "7" }, { value: "8" }],
    goodAnswer: "8",
    choseAnswer: null,
  },
  {
    id: "10",
    value: "Soutenez-nous sur Tipeee",
    syllabes: ["Sou", "te", "nez", "-nous", " ", "sur", " ", "Ti", "peee"],
    answers: [{ value: "5" }, { value: "6" }, { value: "7" }, { value: "8" }],
    goodAnswer: "7",
    choseAnswer: null,
  },
]

type Question = {
  id: string
  value: string
  syllabes?: string[]
  answers: {
    value: string
  }[]
  goodAnswer: string
  choseAnswer: string
}

const Quizz: React.FC<{
  onFinish: (questions: Question[]) => void
  questions: Question[]
}> = props => {
  const [current, setCurrent] = useState(0)
  const [answerSelected, selectAnswer] = useState(null)
  const [answers, setAnswers] = useState([...props.questions])

  const onSubmit = () => {
    if (!answerSelected) return false

    const addChoseAnswerToAnswers = answers.map((answer, index) => ({
      ...answer,
      choseAnswer: current === index ? answerSelected : answer.choseAnswer,
    }))

    if (current === questions.length - 1)
      return props.onFinish(addChoseAnswerToAnswers)

    setAnswers(addChoseAnswerToAnswers)

    setCurrent(current + 1)
    selectAnswer(null)
  }

  const onBack = () => {
    if (current === 0) return false
    setCurrent(current - 1)
    selectAnswer(null)
  }

  return (
    <main className="grid w-full h-screen text-gray-900 bg-gray-50">
      <div className="w-full">
        <div className="relative flex items-center">
          <div className="absolute top-0 bottom-0 left-0 flex items-center px-2 py-2 md:pl-8">
            <Link
              to="/quizz/"
              className="flex items-center justify-center w-10 h-10 duration-300 ease-in-out bg-gray-200 rounded-md group md:bg-transparent md:px-2 md:pr-4 md:h-full md:w-full hover:bg-gray-200"
            >
              <ChevronLeftIcon className="w-6 h-6 text-gray-600 duration-300 ease-in-out group-hover:text-gray-900 md:w-6 md:h-6" />
              <span className="hidden ml-4 text-sm font-bold text-gray-700 uppercase duration-300 ease-in-out transform opacity-0 group-hover:text-gray-900 md:inline group-hover:opacity-100">
                Retour aux quizz
              </span>
            </Link>
          </div>
          <div className="w-full py-6 text-sm font-bold text-center text-gray-700 uppercase md:text-lg">
            Combien de syllabes ?
          </div>
        </div>

        <Steps steps={answers.length} current={current} />
      </div>

      {answers.map((question, index) => (
        <Transition
          appear
          show={index === current}
          enter="transition duration-1000 transform ease-in-out"
          enterFrom="opacity-0 translate-x-10"
          enterTo="opacity-100 translate-x-0"
        >
          <div className="flex items-center justify-center h-full px-4 font-serif text-2xl text-center text-gray-900 md:text-4xl">
            {question.value}
          </div>
        </Transition>
      ))}

      <div className="flex flex-col justify-end w-full max-w-xl pb-6 mx-auto">
        {answers.map(({ answers }, index) => (
          <Transition show={index === current}>
            <div className="grid grid-cols-2 gap-4 px-4">
              {answers.map(({ value }, i) => (
                <Transition.Child
                  appear
                  as="div"
                  enter={`transition transform duration-700 ease-in-out ${
                    i === 1
                      ? "delay-100"
                      : i === 2
                      ? "delay-200"
                      : i === 3
                      ? "delay-300"
                      : ""
                  }`}
                  enterFrom="opacity-0 -translate-y-4"
                  enterTo="opacity-100 translate-y-0"
                >
                  <Answer
                    value={value}
                    key={value}
                    selected={value === answerSelected}
                    onClick={() => selectAnswer(value)}
                  />
                </Transition.Child>
              ))}
            </div>
          </Transition>
        ))}

        <div className="px-4 mt-4">
          <div
            onClick={onSubmit}
            className={`block w-full cursor-pointer p-4 text-xl font-medium text-center text-white duration-300 ease-in-out bg-green-500 rounded-lg shadow-lg  hover:bg-green-600`}
          >
            Valider
          </div>
          <div
            onClick={onBack}
            className="block w-full p-4 mt-4 text-lg font-medium text-center text-gray-600 duration-300 ease-in-out rounded-lg cursor-pointer hover:bg-gray-200 hover:text-gray-900"
          >
            Retour
          </div>
        </div>
      </div>
    </main>
  )
}

const Success: React.FC<{ questions: Question[] }> = props => {
  const query = useStaticQuery(graphql`
    query GetTrophyImage {
      file(relativePath: { eq: "illustrations/trophy.png" }) {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            width: 1200
            quality: 80
          )
        }
      }
    }
  `)

  const illustration = getImage(query.file.childImageSharp)

  const totalGoodAnswers = props.questions.filter(
    ({ choseAnswer, goodAnswer }) => {
      return choseAnswer === goodAnswer
    }
  ).length

  return (
    <div className="w-full">
      <Confetti isOpen />

      <main>
        <Navigation />
        <Hero
          illustration={illustration}
          theme={{
            as: "div",
            value: (
              <Breadcrumb
                items={[
                  {
                    label: "Quizz",
                    url: "/quizz/",
                  },
                  {
                    label: "Félicitations vous avez terminé le quizz !",
                  },
                ]}
              />
            ),
          }}
          title={{
            as: "h1",
            value: (
              <>
                Vous avez eu{" "}
                <span className="text-green-600">
                  {totalGoodAnswers} bonne{totalGoodAnswers > 1 ? "s" : ""}{" "}
                  réponse{totalGoodAnswers > 1 ? "s" : ""}
                </span>{" "}
                !
              </>
            ),
          }}
          description={{
            value: (
              <>
                Bravo et merci d'avoir terminé le quizz sur les syllabes !
                <br />
                <br />
                Si vous avez apprécié l'expérience, n'hésitez pas à nous le
                faire savoir sur nos pages Instagram, Facebook ou Twitter, nous
                serions ravis de renouveler l'expérience.
                <br />
                <br />
                Vous pouvez retrouver toutes les corrections, plus bas dans la
                page, vous pourrez voir comment les syllabes sont formées pour
                chaque vers et comprendre vos erreurs si vous en avez fait.
                <br />
                <br />
                <strong>Partagez vos résultats et défiez vos amis !</strong>
                <Share success={totalGoodAnswers} />
              </>
            ),
          }}
        />

        <div className="relative pb-16">
          <main className="px-4 mx-auto mt-8 max-w-7xl sm:px-6">
            <div className="mt-12 text-3xl font-bold">
              La correction des réponses
            </div>
            <Correction
              corrections={props.questions.map(
                ({ value, syllabes, id, choseAnswer, goodAnswer }) => ({
                  succeed: choseAnswer === goodAnswer,
                  text: syllabes,
                  answer: goodAnswer,
                  id,
                })
              )}
            />
          </main>
        </div>

        <Footer />
      </main>
    </div>
  )
}

export default function QuizzSyllabes() {
  const [tab, setTab] = useState<"quizz" | "success">("quizz")
  const [finishAnswers, setFinishAnswers] = useState<Question[]>([])

  const onFinish = (questions: Question[]) => {
    setFinishAnswers(questions)
    setTab("success")
  }

  return (
    <>
      <Seo
        title="Quizz | Combien de syllabes contiennent ces vers ?"
        description="Découvrez combien de syllabes contiennent les vers proposés et partagez vos résultats !"
        index={false}
      />

      {tab === "quizz" && <Quizz questions={questions} onFinish={onFinish} />}
      {tab === "success" && <Success questions={finishAnswers} />}
    </>
  )
}

import React, { useState } from "react"
import LinkIcon from "@heroicons/react/outline/LinkIcon"
import ShareIcon from "@heroicons/react/outline/ShareIcon"
import CheckIcon from "@heroicons/react/outline/CheckIcon"
import DownloadIcon from "@heroicons/react/outline/DownloadIcon"

import Facebook from "../../assets/quizz/badges/facebook.svg"
import Twitter from "../../assets/quizz/badges/twitter.svg"
import WhatsApp from "../../assets/quizz/badges/whatsapp.svg"

import { createBadgeUrl, hash } from "../../utils/createBadgeUrl"

const CopyToClipboad: React.FC<{
  text: string
  children: (copied: boolean) => React.ReactNode
}> = props => {
  const [copied, setCopied] = useState(false)
  return (
    <div
      onClick={() => {
        const copyText = document.querySelector("#copy")
        // @ts-ignore
        if (copyText && copyText.select) copyText.select()
        if (document.execCommand) document.execCommand("copy")
        setCopied(true)
      }}
    >
      {props.children(copied)}
      <input
        id="copy"
        className="absolute"
        style={{ left: "-100000px", top: "-10000px" }}
        value={props.text}
      />
    </div>
  )
}

const Network: React.FC<{
  backgroundColor: string
  share?: string
  download?: boolean
  onClick?: Function
}> = ({ children, download, backgroundColor, share, onClick }) => {
  if (share)
    return (
      <a
        href={share}
        target="_blank"
        className="flex items-center justify-center w-12 h-12 transition-shadow duration-300 rounded-lg shadow cursor-pointer hover:shadow-lg"
        style={{ backgroundColor }}
      >
        {children}
      </a>
    )

  return (
    <div
      {...(onClick ? { onClick: () => onClick() } : {})}
      className="flex items-center justify-center w-12 h-12 transition-shadow duration-300 rounded-lg shadow cursor-pointer hover:shadow-lg"
      style={{ backgroundColor }}
    >
      {children}
    </div>
  )
}

export const Share: React.FC<{ success: number }> = props => {
  const shareable = !!navigator.share

  const params = {
    quizz: "syllabes",
    success: props.success,
  }
  const shareLink = `${createBadgeUrl(params)}`
  const hashParams = hash(params)

  return (
    <div className="flex mt-8 space-x-2">
      <Network
        backgroundColor="#1877F2"
        share={`https://www.facebook.com/sharer.php?u=${shareLink}`}
      >
        <img src={Facebook} className="w-8 h-8" />
      </Network>
      <Network
        backgroundColor="#1DA1F2"
        share={`https://twitter.com/intent/tweet?url=${shareLink}&text=${encodeURIComponent(
          "Je viens de terminer ce quizz du @temple_du_haiku ! Qui sera capable de faire mieux ? #haïku #japon"
        )}`}
      >
        <img src={Twitter} className="w-8 h-8" />
      </Network>
      <Network
        backgroundColor="#25D366"
        share={`https://api.whatsapp.com/send?text=${encodeURIComponent(
          `Peux-tu faire mieux que moi ? ${shareLink}`
        )}&url=${shareLink}`}
      >
        <img src={WhatsApp} className="w-8 h-8" />
      </Network>

      <Network
        share={`https://u4d9i3q2v7.execute-api.eu-west-1.amazonaws.com/v0?base64=${hashParams}`}
        backgroundColor="#ec489a"
      >
        <DownloadIcon className="w-6 h-6 text-white " />
      </Network>

      {shareable && (
        <Network
          backgroundColor="#111827"
          onClick={() =>
            navigator.share({
              url: shareLink,
            })
          }
        >
          <ShareIcon className="w-6 h-6 text-white" />
        </Network>
      )}
      {!shareable && (
        <CopyToClipboad text={shareLink}>
          {(copied: boolean) => {
            if (copied)
              return (
                <Network backgroundColor="#10B981">
                  <CheckIcon className="w-8 h-8 text-white" />
                </Network>
              )

            return (
              <Network backgroundColor="#111827">
                <LinkIcon className="w-6 h-6 text-white" />
              </Network>
            )
          }}
        </CopyToClipboad>
      )}
    </div>
  )
}

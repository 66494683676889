import React, { ReactElement, useState, useEffect } from "react"
import { Transition } from "@headlessui/react"

export const SyllabeContainer: React.FC<{ credit?: string }> = props => (
  <section className="max-w-screen-md mx-auto">
    <section
      className={`relative py-10 ${
        props.credit ? "" : "pb-20"
      } mt-4 shadow-xl bg-gray-50 md:rounded-md`}
    >
      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative">
          <blockquote
            className={`flex justify-center ${props.credit ? "" : "mt-10"}`}
          >
            <div>
              <div className="max-w-3xl mx-auto font-serif text-2xl italic font-medium leading-9 text-gray-900">
                {props.children}
              </div>
            </div>
          </blockquote>
          {props.credit && (
            <div className="mt-8 text-sm italic text-center text-gray-500">
              {props.credit}
            </div>
          )}
        </div>
      </div>
    </section>
  </section>
)

export const Syllabe: React.FC<{
  popup?: ReactElement
  highlight?: boolean
}> = ({ children, popup, highlight }) => {
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    const fn = () => {
      if (isOpen) setOpen(false)
    }

    if (popup) {
      window.addEventListener("click", fn)

      return () => {
        window.removeEventListener("click", fn)
      }
    }
  }, [isOpen])

  return (
    <div className={`inline-block relative`}>
      {popup && (
        <Transition
          as="div"
          show={isOpen}
          enter="z-10 ease-out duration-200 transform"
          enterFrom="opacity-0 translate-y-2 scale-95"
          enterTo="opacity-100 translate-y-0 scale-100"
          leave="transition ease-in duration-150 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            onClick={() => setOpen(false)}
            className="absolute z-10 flex items-center w-screen max-w-md p-6 py-4 font-sans text-base not-italic font-light transition transform -translate-x-1/2 bg-white border border-gray-100 shadow-sm bottom-full left-1/2 rounded-xl"
          >
            <span>{popup}</span>
            <div className="flex-shrink-0 ml-auto -mr-4">
              <button className="p-2 text-gray-500 transition duration-300 rounded hover:bg-gray-100">
                <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </Transition>
      )}

      <div
        className={`group ${popup && "cursor-pointer"}`}
        onClick={() => popup && setOpen(true)}
      >
        {popup && (
          <div
            className={`${
              !isOpen && "sm:hidden"
            } group-hover:block h-full absolute bottom-0 left-0 w-11/12 margin-auto bg-yellow-200`}
          />
        )}

        <div
          className={`h-0.5 absolute bottom-0 left-0 w-11/12 margin-auto bg-gray-400 ${
            (popup || highlight || isOpen) && "bg-yellow-400"
          }`}
        />

        <span className="relative">{children}</span>
      </div>
    </div>
  )
}

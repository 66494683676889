export const createBadgeUrl = (params: { quizz: string; success: number }) => {
  return `https://quizz.temple-du-haiku.fr/` + hash(params)
}

export const hash = (params: { quizz: string; success: number }) => {
  return window.btoa(
    unescape(
      encodeURIComponent(`success=${params.success}&quizz=${params.quizz}`)
    )
  )
}
